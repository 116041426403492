<template>
    <div class="fixed top-16 bottom-0 right-0 bg-dp-1 border-r w-full p-6 pt-0 px-container sm:px-6 sm:w-[420px] max-w-full flex flex-col overflow-auto z-30">  
        <header class="flex items-center gap-4 border-b py-6 font-semibold bg-dp-1 sticky top-0">
            <Author class="-my-2" :user="profile"></Author>
            <svg-icon class="ml-auto rotate-90" name="down" width="24" height="24" @click="emitter.emit('chat:open')" />
        </header>

        <div class="flex-1 flex gap-4 overflow-hidden" bind:this={chatBodyContainer}>
            <div class="flex flex-col min-w-full chat__conversation">
                <div class="messages scrollbar flex flex-col gap-1.5 flex-1 overflow-auto py-4" ref="messagesContainer">
                    <template v-for="(messages, key) in groupedMessages">
                        <span class="text-xs bg-slate-600 text-white self-center px-2 py-0.5 rounded-full sticky top-0">{{ key }}</span>
                        <div class="flex w-full" v-for="(message, index) in messages">
                            <div :class="`text-sm rounded-2xl bg-white py-1 px-3 triangle-top border-[0.5px] -z-10 ${message.sender_id == client?.client_id ? 'ml-auto' : ''}`" >
                                <div v-if="message.images" class="flex flex-col gap-1 mb-1">
                                    <img v-for="uuid in message.images" class="bg-white p-1 rounded object-cover aspect-square" :src="`/files/uploaded/${uuid}.webp`" />
                                </div>
                                {{ message.text }}
                                <div class="inline-flex text-[10px] text-right">
                                    <span class="text-green-600 font-bold" v-if="message.is_readed">✓</span>
                                    <i class="text-slate-400">{{getMessageTime(message.created_at)}}</i>
                                </div>
                                <span v-if="index == messages.length - 1">{{ scrollToBottom() }}</span>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="relative">
                    <div class="absolute flex gap-1 bottom-px">
                        <div class="grid place-items-center" v-for="uuid in attachedImages">
                            <img class="bg-white p-1 rounded object-cover aspect-square w-16 row-start-1 col-start-1" :src="`/files/uploaded/${uuid}.webp`" />
                            <svg-icon @click="() => attachedImages = attachedImages.filter(iuuid => iuuid != uuid)" class="row-start-1 col-start-1 z-10 bg-white/m rounded-full cursor-pointer" name="close" width="24" height="24" />
                        </div>
                    </div>
                </div>
                <form class="sticky bottom-0 " @submit.prevent="addMessage">
                    <input type="hidden" name="receiver_id" :value="profile.id">
                    <div class="bg-white border py-2 px-4 rounded-lg flex items-center gap-2">
                        <input class="outline-0 w-full" type="text" name="text" :required="!attachedImages.length">
                        <Upload class="text-slate-400" :notext="true" @uploaded="(images:any) => attachedImages = attachedImages.concat(images.map(i => i.uuid))" multiple="true" />
                        <button class="grid place-items-center -mr-2 text-blue-600">
                            <svg-icon name="send" width="24" height="24"></svg-icon>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .ml-auto.bg-white {
        @apply bg-green-100;
    }
</style>

<style>
    @keyframes detect-scroll {
        from, to { --can-scroll: ; }
    }

    .messages {
        animation: detect-scroll linear;
        animation-timeline: scroll(self);

        --bg-if-can-scroll: var(--can-scroll) 8px;
        padding-right: var(--bg-if-can-scroll, 0);
    }
</style>

<script setup lang="ts">
    import { useMarkAsReadMutation, useMessagesSubscription } from '~/composables/urql';
    import useEmitter from '~/composables/useEmitter';

    const emitter = useEmitter();
    const props = defineProps(['profile']);
    const client = useUser();
    const messagesContainer = ref();
    const attachedImages = ref([]);

    // Сейчас запрашиваем абсолютно все сообщения а затем фильтруем
    const { data } = await useMessagesSubscription({ variables: { profile_id: props.profile.id } });
    const groupedMessages = computed(() => {
        return data.value?.uogorod_messages.reduce((acc:any, item) => {
            const t = relativeDate(item.created_at);
            const key = new Date(item.created_at).toDateString() == new Date().toDateString() ? 'сегодня' : t;
            
            if (!acc[key as string]) {
                acc[key as string] = [];
            }

            acc[key as string].push(item);

            return acc;
        }, {});
    });
    const { executeMutation: insertMessage } = useAddMessageMutation();
    const { executeMutation: markAsRead } = useMarkAsReadMutation();

    const addMessage = async (e:any) => {
        // FIXME валидация должна смотреть что если нет
        const { receiver_id, text } = e.target;

        await insertMessage({
            receiver_id: receiver_id.value,
            images: attachedImages.value,
            text: text.value,
        });

        text.value = '';
        attachedImages.value = [];
    }
    const getMessageTime = (date:any) => new Date(date).toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' });
    const scrollToBottom = () => {
        messagesContainer.value?.scrollTo({
            top: messagesContainer.value?.scrollHeight,
            behavior: 'instant'
        });
        markAsRead({ profile_id: props.profile.id });
    }

    // onMounted();
</script>